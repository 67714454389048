import { API, setAuthToken } from '.';

const state = () => ({
  role: -1,
  email: null,
  name: null,
  isChecked: false,
  loadingUser: false,
  loadingAction: false,
  loginErrorInvalid: false,
  loginErrorUnconfirmed: false,
  registerError: false,
  successVisible: false,
  passResetError: false
});

const getters = {
  isUser: state => state.role > 1,
  isAdmin: state => state.role >= 8
};

const mutations = {
  setIsChecked (state, value) {
    state.isChecked = value;
  },
  setAccount (state, { email, name, role, id }) {
    state.email = email;
    state.name = name;
    state.role = role;
    if (window.dataLayer) { window.dataLayer.push({ event: 'userData', userId: id }); }
  },
  setLoadingUser (state, value) {
    state.loadingUser = value;
  },
  setLoadingAction (state, value) {
    state.loadingAction = value;
  },
  setLoginErrorInvalid (state, value) {
    state.loginErrorInvalid = value;
  },
  setLoginErrorUnconfirmed (state, value) {
    state.loginErrorUnconfirmed = value;
  },
  setRegisterError (state, value) {
    state.registerError = value;
  },
  setSuccessVisible (state, value) {
    state.successVisible = value;
  },
  setPassResetError (state, value) {
    state.passResetError = value;
  },
  resetPasswordResetState (state) {
    state.successVisible = false;
    state.passResetError = false;
  }
};

const actions = {
  register ({ commit }, { email, password, name }) {
    commit('setLoadingAction', true);
    API.post('/api/users', { email, password, name })
      .then(({ data }) => {
        commit('setRegisterError', false);
        commit('setAccount', { email: null, token: null, name: null, role: 0, id: null });
        commit('setLoadingAction', false);
      }, error => {
        if (error.response.status === 409) {
          console.log('error', error);
          commit('setRegisterError', true);
          commit('setLoadingAction', false);
        } else {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          commit('setLoadingAction', false);
        }
      });
  },

  login ({ commit, dispatch }, { email, password, router, route }) {
    commit('setLoadingAction', true);
    API.post('/api/token', { email, password })
      .then(({ data }) => {
        commit('setLoginErrorInvalid', false);
        commit('setLoginErrorUnconfirmed', false);
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        setAuthToken(data.accessToken);
        dispatch('getUser');
        commit('setLoadingAction', false);
        router.push(route.query.redirect || '/');
      }, error => {
        if (error.response.status === 401 || error.response.status === 404) {
          commit('setLoginErrorInvalid', true);
          commit('setLoginErrorUnconfirmed', false);
        } else if (error.response.status === 403) {
          commit('setLoginErrorInvalid', false);
          commit('setLoginErrorUnconfirmed', true);
        } else {
          commit('setLoginErrorInvalid', false);
          commit('setLoginErrorUnconfirmed', false);
          commit('display/setError', { value: true }, { root: true });
        }
        commit('setLoadingAction', false);
      });
  },

  refreshToken ({ state }) {
    const refreshToken = localStorage.getItem('refreshToken');
    return new Promise((resolve, reject) => {
      API.post('/api/token/refresh', { email: state.email, refreshToken })
        .then(({ data }) => {
          localStorage.setItem('token', data.accessToken);
          localStorage.setItem('refreshToken', data.refreshToken);
          setAuthToken(data.accessToken);
          resolve();
        }, error => {
          console.log('error', error);
          reject(error);
        });
    });
  },

  resetPassword ({ commit }, { email }) {
    commit('setLoadingAction', true);
    API.post('/api/password', { email })
      .then(() => {
        commit('setSuccessVisible', true);
        commit('setPassResetError', false);
        commit('setLoadingAction', false);
      }, error => {
        console.log('error', error);
        if (error.response.status === 409) {
          commit('setSuccessVisible', true);
          commit('setPassResetError', true);
        } else {
          commit('display/setError', { value: true }, { root: true });
        }
        commit('setLoadingAction', false);
      });
  },

  setPassword ({ commit }, { password, token, router }) {
    commit('setLoadingAction', true);
    API.post('/api/password', { password, passToken: token })
      .then(() => {
        commit('setSuccessVisible', true);
        commit('setLoadingAction', false);
      }, error => {
        console.log('error', error);
        if (error.response.status === 404 || error.response.status === 409) {
          commit('display/setError', {
            value: true,
            text: 'Izskatās, ka šī paroles atjaunošanas saite vairs nav derīga. Lūdzu, atkārtoti pieprasiet paroles atjaunošanas saiti.',
            callBack: () => { router.push('/parole'); }
          }, { root: true });
        } else {
          commit('display/setError', { value: true }, { root: true });
        }
        commit('setLoadingAction', false);
      });
  },

  logout () {
    localStorage.removeItem('token');
    setAuthToken(null);
    location.reload();
  },

  getUser ({ commit, dispatch }) {
    const token = localStorage.getItem('token');
    if (token) {
      commit('setLoadingUser', true);
      setAuthToken(token);
      return new Promise((resolve, reject) => {
        API.get('/api/users/me')
          .then(({ data }) => {
            commit('setAccount', data);
            commit('setLoadingUser', false);
            resolve();
          }, error => {
            console.log('error', error);
            commit('setLoadingUser', false);
            dispatch('logout');
            reject(error);
          });
      });
    }
  }
};

export default { state, getters, mutations, actions, namespaced: true };
