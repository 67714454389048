<template>
  <div
    class="home-video-container"
  >
    <base-video-youtube
      v-if="videoId"
      :id="videoId"
    />
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import BaseVideoYoutube from './BaseVideoYoutube';
export default {
  components: { BaseVideoYoutube },
  setup () {
    const store = useStore();

    const videoId = computed(() => {
      if (store.state.contents.homeVideo) {
        return store.state.contents.homeVideo.params.id;
      } else {
        return null;
      }
    });

    onMounted(() => store.dispatch('contents/getContent', { category: 'homeVideo', single: true }));

    return {
      videoId
    };
  }
};
</script>

<style>

</style>
