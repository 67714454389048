import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';

const routes = [
  {
    path: '/',
    name: '',
    component: HomePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rekolekcijas',
    name: 'Rekolekcijas',
    component: () => import(/* webpackChunkName: "rekolekcijas" */ '../views/RetreatsPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/padzilinata-programma',
    name: 'Padziļinātā programma',
    component: () => import(/* webpackChunkName: "padzilinata" */ '../views/AdvancedProgramPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/individuala-kalposana',
    name: 'Individuāla kalpošana',
    component: () => import(/* webpackChunkName: "individuala" */ '../views/IndividualProgramPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jautajumi',
    name: 'Biežāk uzdotie jautājumi',
    component: () => import(/* webpackChunkName: "jautajumi" */ '../views/FAQPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rekolekciju-ieraksti',
    name: 'Rekolekciju videoieraksti',
    component: () => import(/* webpackChunkName: "video" */ '../views/ResourcesRetreats.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rekolekciju-ieraksti/:id',
    name: 'Rekolekciju videoieraksts',
    component: () => import(/* webpackChunkName: "video" */ '../views/ResourceGroup.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/svetdienas-sarunas',
    name: 'Svētdienas sarunas',
    component: () => import(/* webpackChunkName: "video" */ '../views/ResourcesPodcasts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/par',
    name: 'Par mums',
    component: () => import(/* webpackChunkName: "par" */ '../views/AboutPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/atbalsts',
    name: 'Atbalsts',
    component: () => import(/* webpackChunkName: "atbalsts" */ '../views/SupportPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profils',
    name: 'Profils',
    component: () => import(/* webpackChunkName: "profils" */ '../views/ProfilePage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registreties',
    name: 'Reģistrēties',
    component: () => import(/* webpackChunkName: "registreties" */ '../views/RegisterPage.vue'),
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/ienakt',
    name: 'Ienākt',
    component: () => import(/* webpackChunkName: "ienakt" */ '../views/LoginPage.vue'),
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/parole',
    name: 'Paroles atjaunošana',
    component: () => import(/* webpackChunkName: "parole1" */ '../views/ResetPasswordPage.vue'),
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/parole/:token',
    name: 'Paroles maiņa',
    component: () => import(/* webpackChunkName: "parole2" */ '../views/SetPasswordPage.vue'),
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/admin',
    name: 'Pārvaldības panelis',
    component: () => import(/* webpackChunkName: "admin1" */ '../views/AdminPage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/lietotaji',
    name: 'Lietotāju pārvaldība',
    component: () => import(/* webpackChunkName: "admin2" */ '../views/AdminUsersPage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/saturs',
    name: 'Satura pārvaldība',
    component: () => import(/* webpackChunkName: "admin3" */ '../views/AdminContentsPage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/resursi',
    name: 'Resursu pārvaldība',
    component: () => import(/* webpackChunkName: "admin3" */ '../views/AdminResourcesPage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/individuala-kalposana',
    name: 'Individuālā kalpošana',
    component: () => import(/* webpackChunkName: "admin4" */ '../views/AdminIndividualCarePage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/kalposana',
    name: 'Lietotāju informācija',
    component: () => import(/* webpackChunkName: "admin4" */ '../views/AdminCarePage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/rekolekcijas',
    name: 'Dalība rekolekcijās',
    component: () => import(/* webpackChunkName: "admin4" */ '../views/AdminRetreatsPage.vue'),
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/privatuma-politika',
    name: 'Privātuma politika',
    component: () => import(/* webpackChunkName: "privatums" */ '../views/PrivacyPolicyPage.vue')
  }
];

export default function (store) {
  const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return { el: to.hash };
      } else {
        return { top: 0 };
      }
    }
  });

  router.beforeEach((to, from, next) => {
    function doRoute () {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters['account/isAdmin']) {
          next();
        } else {
          next({ path: '/' });
        }
      } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['account/isUser']) {
          next();
        } else {
          next({
            path: '/ienakt',
            query: { redirect: to.fullPath }
          });
        }
      } else if (to.matched.some(record => record.meta.guestOnly)) {
        if (store.getters['account/isUser']) {
          next({ path: '/' });
        } else {
          next();
        }
      } else {
        next();
      }
    };

    if (store.state.account.isChecked) {
      doRoute();
    } else {
      store.dispatch('account/getUser')
        .then(() => doRoute(), () => doRoute());
    }
  });

  return router;
};
