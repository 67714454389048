<template>
  <div>
    <live-stream v-if="liveStreamVisible" />
    <div
      v-else
      class="container-main"
    >
      <home-statement />
    </div>
    <base-title>
      Tevi varētu interesēt
    </base-title>
    <div class="container-main">
      <user-interest-panel />
    </div>
    <base-title>
      Aktualitātes
    </base-title>
    <div class="container-main">
      <home-video />
      <home-news />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import BaseTitle from '../components/BaseTitle';
import HomeStatement from '../components/HomeStatement';
import UserInterestPanel from '../components/UserInterestPanel';
import HomeNews from '../components/HomeNews';
import LiveStream from '../components/LiveStream';
import HomeVideo from '@/components/HomeVideo.vue';

export default {
  components: { BaseTitle, HomeStatement, UserInterestPanel, HomeNews, LiveStream, HomeVideo },
  setup () {
    const store = useStore();
    const liveStreamVisible = computed(() => store.getters['contents/liveStreamVisible']);

    onMounted(() => store.dispatch('contents/getContent', { category: 'liveStream', single: true }));

    return {
      liveStreamVisible
    };
  }
};
</script>

<style>
.carousel-container {
  background-color: var(--color-background-secondary);
  height: auto;
  padding: 0 1rem;
}
.carousel-contents {
  display: block;
  max-width: 40rem;
  margin: 0 auto 32px;
}
.carousel-card {
  box-shadow: var(--shadow);
  margin: 0 16px;
  background-color: white;
  border-radius: 8px;
  padding: 16px 24px;
}
.ant-carousel .slick-dots-bottom {
  bottom: 20px;
}
.ant-carousel .slick-dots li button {
  height: 16px;
  border-radius: 16px;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  z-index: 1;
  width: 32px;
  height: 32px;
  font-size: 32px;
  color: white;
  background-color: transparent;
  top: calc(50% - 16px);
}
.ant-carousel .slick-prev {
  left: max(calc(50% - 20rem - 48px), -1rem);
}
.ant-carousel .slick-next {
  right: max(calc(50% - 20rem - 48px), -1rem);
}
</style>
