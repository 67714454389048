import { API } from '.';
import dayjs from 'dayjs';

const state = () => ({
  dateList: [],
  dateListLoading: false,
  adminView: false,
  filterFrom: null,
  filterTo: null,
  myReservations: [],
  myReservationsLoading: false,
  messageSending: false,
  messageSuccessful: false,
  messageError: false,
  userOptions: []
});

const getters = {
};

const mutations = {
  setDateList (state, value) {
    state.dateList = value.map(d => { return { ...d, date: dayjs(d.date) }; });
  },

  setDateListLoading (state, value) {
    state.dateListLoading = value;
  },

  setAdminView (state, value) {
    state.adminView = value;
  },

  setMyReservations (state, value) {
    state.myReservations = value.map(d => { return { ...d, date: dayjs(d.date) }; });
  },

  setMyReservationsLoading (state, value) {
    state.myReservationsLoading = value;
  },

  messageStatusSending (state) {
    state.messageSending = true;
    state.messageSuccessful = false;
    state.messageError = false;
  },
  messageStatusSuccessful (state) {
    state.messageSuccessful = true;
    state.messageSending = false;
    state.messageError = false;
  },
  messageStatusError (state) {
    state.messageSuccessful = false;
    state.messageSending = false;
    state.messageError = true;
  },
  messageStatusNone (state) {
    state.messageSuccessful = false;
    state.messageSending = false;
    state.messageError = false;
  },

  setUserOptions (state, value) {
    state.userOptions = value.map(user => { return { value: user.id, label: user.name }; });
  }
};

const actions = {
  getDateList ({ commit, state }) {
    commit('setDateListLoading', true);
    const path = state.adminView ? '/api/care-dates' : '/api/care-dates/free';
    const params = state.adminView ? { from: state.filterFrom, to: state.filterTo } : {};
    return new Promise((resolve, reject) => {
      API.get(path, { params })
        .then(({ data }) => {
          commit('setDateList', data);
          commit('setDateListLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setDateListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  createDates ({ commit, dispatch }, { dateList, duration, allowInPerson, allowOnline }) {
    commit('setDateListLoading', true);
    const promises = dateList.map(date => API.post('/api/care-dates', { date, duration, allowInPerson, allowOnline }));
    return new Promise((resolve, reject) => {
      Promise.allSettled(promises)
        .then(() => {
          dispatch('getDateList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setDateListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  removeDate ({ commit, dispatch }, id) {
    commit('setDateListLoading', true);
    return new Promise((resolve, reject) => {
      API.delete(`/api/care-dates/${id}`)
        .then(() => {
          dispatch('getDateList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setDateListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  reserveDate ({ commit, dispatch }, { id, location }) {
    commit('setDateListLoading', true);
    return new Promise((resolve, reject) => {
      API.post('/api/care-users/me/care-dates', { availableCareDateId: id, location })
        .then(() => {
          Promise.allSettled([
            dispatch('getDateList'),
            dispatch('getMyReservations')
          ])
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setDateListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  getMyReservations ({ commit }) {
    commit('setMyReservationsLoading', true);
    return new Promise((resolve, reject) => {
      API.get('/api/care-users/me/care-dates/future')
        .then(({ data }) => {
          commit('setMyReservations', data);
          commit('setMyReservationsLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setMyReservationsLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  removeMyReservation ({ commit, dispatch }, id) {
    commit('setMyReservationsLoading', true);
    return new Promise((resolve, reject) => {
      API.delete(`/api/care-users/me/care-dates/${id}`)
        .then(() => {
          Promise.allSettled([
            dispatch('getDateList'),
            dispatch('getMyReservations')
          ])
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setMyReservationsLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  sendMessage ({ commit }, text) {
    commit('messageStatusSending');
    return new Promise((resolve, reject) => {
      API.post('/api/mail', { type: 'individualCareRegister', text })
        .then(() => {
          commit('messageStatusSuccessful');
          resolve();
        }, error => {
          console.log('error', error);
          commit('messageStatusError');
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  getUserOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      API.get('/api/users')
        .then(({ data }) => {
          commit('setUserOptions', data);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setUserOptions', []);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  registerUserOnDate ({ commit, dispatch }, { dateId, userId, location }) {
    commit('setDateListLoading', true);
    return new Promise((resolve, reject) => {
      API.patch(`/api/care-dates/${dateId}`, { userId, location })
        .then(() => {
          dispatch('getDateList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },

  removeUserOnDate ({ commit, dispatch }, dateId) {
    commit('setDateListLoading', true);
    return new Promise((resolve, reject) => {
      API.patch(`/api/care-dates/${dateId}`, { userId: null, location: null })
        .then(() => {
          dispatch('getDateList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
