<template>
  <a-row :gutter="[16,16]">
    <a-col
      :xs="24"
      :md="12"
      :lg="8"
    >
      <user-individual-register-card />
    </a-col>
    <a-col
      v-if="nextEvent"
      :xs="24"
      :md="12"
      :lg="8"
    >
      <next-retreat-event-card
        :event="nextEvent"
      />
    </a-col>
    <a-col
      v-if="spotlightResource"
      :xs="24"
      :md="12"
      :lg="8"
    >
      <resource-card
        :title="spotlightResource.title"
        :link="spotlightResource.params.link"
      />
    </a-col>
  </a-row>
</template>

<script>
import UserIndividualRegisterCard from './UserIndividualRegisterCard';
import NextRetreatEventCard from './NextRetreatEventCard';
import { useStore } from 'vuex';
import { onMounted, computed } from 'vue';
import ResourceCard from './ResourceCard.vue';
export default {
  components: { UserIndividualRegisterCard, NextRetreatEventCard, ResourceCard },
  setup () {
    const store = useStore();

    const nextEvent = computed(() => store.state.contents.retreatEvent ? store.state.contents.retreatEvent[0] : null);
    const spotlightResource = computed(() => store.state.contents.spotlightResource);

    onMounted(() => {
      store.dispatch('contents/getContent', { category: 'retreatEvent', single: false });
      store.dispatch('contents/getContent', { category: 'spotlightResource', single: true });
    });

    return {
      nextEvent,
      spotlightResource
    };
  }
};
</script>

<style scoped>
.card {
  margin: 0;
  padding: 1rem;
  height: 100%;
}
</style>
