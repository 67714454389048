<template>
  <h3>
    Uzdotie jautājumi
    <a-button
      type="dashed"
      shape="circle"
      data-testId="toggleFilter"
      @click="toggleFilter"
    >
      <template #icon>
        <FilterOutlined />
      </template>
    </a-button>
  </h3>
  <a-radio-group
    v-show="filterVisible"
    v-model:value="filter"
    data-testId="filter"
  >
    <a-radio
      value="unanswered"
      data-testId="filterUnanswered"
    >
      Neatbildētie
    </a-radio>
    <a-radio
      value="all"
      data-testId="filterAll"
    >
      Visi
    </a-radio>
  </a-radio-group>
  <a-spin
    :spinning="questionsLoading"
  >
    <live-stream-question-show-item
      v-for="question in questions"
      :key="question.id"
      :question="question"
    />
  </a-spin>
</template>

<script>
import { onBeforeUnmount, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';

import LiveStreamQuestionShowItem from './LiveStreamQuestionShowItem';
import { FilterOutlined } from '@ant-design/icons-vue';

export default {
  components: { LiveStreamQuestionShowItem, FilterOutlined },
  setup () {
    const store = useStore();

    const filter = computed({
      get () {
        return store.state.questions.filter;
      },
      set (val) {
        store.dispatch('questions/setFilter', val);
      }
    });

    const questionsLoading = computed(() => store.state.questions.showSending);

    let intervalID = null;

    const questions = computed(() => store.state.questions.questions);

    const filterVisible = ref(false);

    function getQuestions () {
      store.dispatch('questions/getQuestions');
    }

    function toggleFilter () {
      filterVisible.value = !filterVisible.value;
    }

    onMounted(() => {
      getQuestions();
      intervalID = window.setInterval(getQuestions, 5000);
    });

    onBeforeUnmount(() => {
      clearInterval(intervalID);
    });

    return {
      filter,
      questions,
      questionsLoading,
      filterVisible,
      toggleFilter
    };
  }
};
</script>

<style scoped>

</style>
