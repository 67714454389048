<template>
  <base-user-interest-card title="Aktuāls resurss">
    <p>
      <a-row
        :gutter="[8,8]"
        type="flex"
        class="contents-row"
      >
        <a-col flex="0 0 auto">
          <YoutubeFilled />
        </a-col>
        <a-col flex="1 1 auto">
          {{ title }}
        </a-col>
      </a-row>
    </p>
    <router-link :to="link">
      <a-button type="primary">
        Skatīt
      </a-button>
    </router-link>
  </base-user-interest-card>
</template>

<script>
import { YoutubeFilled } from '@ant-design/icons-vue';
import BaseUserInterestCard from './BaseUserInterestCard.vue';

export default {
  components: { YoutubeFilled, BaseUserInterestCard },
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.contents-row {
  flex-wrap: nowrap;
}
h2 {
  margin-bottom: 0;
}
</style>
