import { createApp } from 'vue';
import App from './App.vue';
import {
  Alert,
  AutoComplete,
  Avatar,
  Badge,
  Button,
  Calendar,
  Card,
  Carousel,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Dropdown,
  Form,
  Input,
  InputNumber,
  List,
  Menu,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Table,
  Tag,
  TimePicker
} from 'ant-design-vue';
import createRouter from './router';
import createStore from './store';

import dayjs from 'dayjs';
import 'dayjs/locale/lv';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { } from '@fortawesome/free-regular-svg-icons';
import { faPeopleRoof, faGlobe, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
library.add(faGlobe);
library.add(faPeopleRoof);
library.add(faFileArrowDown);

dayjs.extend(LocalizedFormat);
dayjs.locale('lv');

const app = createApp(App);
const store = createStore();
const router = createRouter(store);

app.use(Alert);
app.use(AutoComplete);
app.use(Avatar);
app.use(Badge);
app.use(Button);
app.use(Calendar);
app.use(Card);
app.use(Carousel);
app.use(Checkbox);
app.use(Col);
app.use(Collapse);
app.use(ConfigProvider);
app.use(DatePicker);
app.use(Descriptions);
app.use(Dropdown);
app.use(Form);
app.use(Input);
app.use(InputNumber);
app.use(List);
app.use(Menu);
app.use(Modal);
app.use(Popconfirm);
app.use(Radio);
app.use(Row);
app.use(Select);
app.use(Space);
app.use(Spin);
app.use(Statistic);
app.use(Switch);
app.use(Table);
app.use(Tag);
app.use(TimePicker);

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.use(store);
app.use(router);
app.mount('#app');
