import { API } from '.';

const state = () => ({
});

const getters = {
};

const mutations = {
  setDownload (state, { category, value }) {
    state[category] = value;
  }
};

const actions = {
  getDownload ({ commit }, category) {
    return new Promise((resolve, reject) => {
      API.get(`/api/downloads/${category}`, { responseType: 'blob' })
        .then(({ data }) => {
          commit('setDownload', { category, value: data });
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
