<template>
  <div v-if="contents">
    <h1>
      {{ contents.title }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-html="contents.contents"
    />
    <!--eslint-enable-->
    <base-link
      v-if="contents.params.link"
      :to="contents.params.link"
      :text="contents.params.linkText || 'Uzzini vairāk'"
    />
  </div>
</template>

<script>
import BaseLink from './BaseLink.vue';

export default {
  components: { BaseLink },
  props: {
    contents: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
</style>
