<template>
  <a-config-provider :locale="lvLV">
    <div>
      <TheHeader />
      <div class="main-view">
        <router-view />
      </div>
      <TheFooter class="footer" />
      <div
        v-if="cookieConsentVisible"
        class="cookie-consent-container"
      >
        <div>Vietnē tiek izmantotas sīktdatnes, lai analizētu datplūsmu.</div>
        <div>Turpinot lietot šo vietni, Jūs piekrītat sīkdatņu izmantošanai.</div>
        <a-row
          :gutter="16"
          justify="center"
          align="middle"
          class="cookie-button-row"
        >
          <a-col>
            <router-link to="/privatuma-politika">
              Privātuma politika
            </router-link>
          </a-col>
          <a-col>
            <a-button
              type="primary"
              data-testId="consent"
              @click="setConsentCookie"
            >
              Piekrītu
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import { Modal } from 'ant-design-vue';
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

import lvLV from 'ant-design-vue/lib/locale/lv_LV';

export default {
  components: { TheHeader, TheFooter },
  setup () {
    const store = useStore();
    const errorModalVisible = computed(() => store.state.display.error);
    const errorModalText = computed(() => store.state.display.errorText);
    const errorOnOkCallback = computed(() => store.state.display.errorOnOkCallback);

    const cookieConsentVisible = ref(getConsentCookie());

    watch(
      () => errorModalVisible.value,
      show => {
        if (show) {
          showError();
        }
      }
    );

    function getConsentCookie () {
      const cookieArray = document.cookie.split(';');
      for (const cookieString of cookieArray) {
        const cookieTrim = cookieString.trim();
        if (cookieTrim.startsWith('cookie_consent=') && cookieTrim.endsWith('1')) {
          return false;
        }
      }
      return true;
    }

    function setConsentCookie () {
      const d = new Date();
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
      const expires = 'expires=' + d.toUTCString();
      document.cookie = 'cookie_consent=1;' + expires + ';path=/';
      cookieConsentVisible.value = false;
    }

    function showError () {
      Modal.error({
        title: errorModalText.value,
        onOk () {
          errorOnOkCallback.value();
        }
      });
    };

    return {
      cookieConsentVisible,
      setConsentCookie,
      lvLV
    };
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grechen+Fuemen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
:root {
  --color-primary: #8C141E;
  --color-primary-darker: #5e0d14;
  --color-secondary: #f5ecd1;

  --color-menu-background: #f9f5e6; /* 10% lighter than secondary */

  --color-text-main: #161d18;
  --color-text-lighter: #1D261F;
  --color-text-darkest: #000000;
  --color-text-lightest: #FFFFFF;
  --color-text-link: var(--color-primary);
  --color-text-header: var(--color-primary-darker);

  --font-size-base: 1rem;
  --font-size-bigger: 1.125rem;
  --font-size-biggest: 1.25rem;
  --font-size-title: 1.5rem;
  --font-size-large: 2rem;
  --font-size-small: 0.75rem;

  --color-background-primary: var(--color-primary);
  --color-background-primary-focus: var(--color-primary-darker);
  --color-background-secondary: var(--color-secondary);
  --color-background-secondary-transparent:  rgb(245, 236, 209, 0.5);

  --color-disabled: #ccc;

  --font-title: 'Grechen Fuemen', cursive;
  --font-accent: 'Roboto Slab', serif;
  --font-bolder: 'Open Sans Condensed', sans-serif;

  --shadow: rgba(0, 0, 0, 0.35) 0px 8px 16px;
}
body {
  overflow-y: scroll;
  font-family: 'Open Sans', sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  color: var(--color-text-main);
  font-size: var(--font-size-base);
}
h1 {
  font-size: var(--font-size-title);
  font-weight: 700;
}
h2 {
  font-size: var(--font-size-biggest);
  font-family: var(--font-bolder);
  color: var(--color-text-lighter);
}
h3 {
  font-family: var(--font-bolder);
  font-size: var(--font-size-bigger);
}
.ant-menu {
  border-bottom: 2px solid transparent;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: white;
}
.container-main {
  padding: 1rem 2rem 3rem 2rem;
  max-width: 75rem;
  margin: 0 auto;
}
.container-main-small {
  padding: 1rem 2rem 3rem 2rem;
  max-width: 50rem;
  margin: 0 auto;
}
.container-main-large {
  padding: 1rem 2rem 3rem 2rem;
  margin: 0 auto;
}
.main-view {
  padding-bottom: 3.5rem;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
}
.cookie-consent-container {
  position: fixed;
  bottom:0%;
  width:100%;
  padding: 2rem 0;
  background: linear-gradient(var(--color-background-secondary-transparent), var(--color-background-secondary) 40%);
  text-align: center;
}
.cookie-button-row {
  margin: 1rem 0 0;
}
.collapse-main > .ant-collapse-item > .ant-collapse-header {
  color: var(--color-text-lightest);
  padding: 2rem;
  font-size: var(--font-size-biggest);
}
.collapse-main > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: none;
  display: inline-block;
}
.ant-table-thead > tr > th {
  font-weight: bold;
}
@media (min-width: 576px) {
  :root {
      --font-size-base: 1rem;
      --font-size-bigger: 1.125rem;
      --font-size-biggest: 1.25rem;
      --font-size-title: 1.5rem;
      --font-size-large: 2rem;
      --font-size-small: 0.875rem;
  }
}
@media (min-width: 992px) {
  :root {
    --font-size-base: 1rem;
    --font-size-bigger: 1.125rem;
    --font-size-biggest: 1.5rem;
    --font-size-title: 1.5rem;
    --font-size-large: 2rem;
    --font-size-small: 0.875rem;
  }

  .card {
    padding: 1rem 2rem;
    margin: 2rem 0;
  }
}
@media (min-width: 1200px) {
  :root {
    --font-size-base: 1.125rem;
    --font-size-bigger: 1.5rem;
    --font-size-biggest: 2rem;
    --font-size-title: 2rem;
    --font-size-large: 2.5rem;
    --font-size-small: 0.875rem;
  }
}
</style>
