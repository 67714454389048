import { API } from '.';
import dayjs from 'dayjs';

const state = () => ({
  retreatList: [],
  retreatListLoading: false,
  adminView: false,
  focusRetreat: null,
  focusRetreatLoading: false
});

const getters = {
  defaultFocusRetreat: state => {
    if (state.retreatList) {
      const futureRetreats = state.retreatList.filter(r => r.dateFrom > dayjs());
      if (futureRetreats.length) {
        return futureRetreats[0];
      } else {
        return state.retreatList.slice(-1)[0];
      }
    } else {
      return null;
    }
  },
  focusRetreatParticipants: state => {
    if (state.focusRetreat && state.focusRetreat.participations) {
      return state.focusRetreat.participations.map(p => p.userId);
    } else {
      return [];
    }
  }
};

const mutations = {
  setRetreatList (state, value) {
    state.retreatList = value.map(d => { return { ...d, dateFrom: dayjs(d.dateFrom), dateTo: dayjs(d.dateTo) }; });
  },

  setRetreatListLoading (state, value) {
    state.retreatListLoading = value;
  },

  setAdminView (state, value) {
    state.adminView = value;
  },

  setFocusRetreat (state, value) {
    state.focusRetreat = { ...value, dateFrom: dayjs(value.dateFrom), dateTo: dayjs(value.dateTo) };
  },

  setFocusRetreatLoading (state, value) {
    state.focusRetreatLoading = value;
  }
};

const actions = {
  getRetreatList ({ commit, state, getters, dispatch }) {
    commit('setRetreatListLoading', true);
    const path = state.adminView ? '/api/retreats' : '/api/retreats/future';
    return new Promise((resolve, reject) => {
      API.get(path)
        .then(({ data }) => {
          commit('setRetreatList', data);
          commit('setRetreatListLoading', false);
          if (!state.focusRetreat) {
            const defaultRetreat = getters.defaultFocusRetreat;
            if (defaultRetreat) {
              dispatch('focusRetreat', defaultRetreat.id);
              resolve();
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        }, error => {
          console.log('error', error);
          commit('setRetreatListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  focusRetreat ({ commit, dispatch }, id) {
    commit('setFocusRetreatLoading', true);
    return new Promise((resolve, reject) => {
      API.get(`/api/retreats/${id}`)
        .then(({ data }) => {
          commit('setFocusRetreat', data);
          commit('setFocusRetreatLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setFocusRetreatLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  createRetreat ({ commit, dispatch }, { title, dateFrom, dateTo }) {
    commit('setRetreatListLoading', true);
    return new Promise((resolve, reject) => {
      const body = {
        title,
        dateFrom: dateFrom.startOf('date'),
        dateTo: dateTo.endOf('date')
      };
      API.post('/api/retreats', body)
        .then(() => {
          dispatch('getRetreatList')
            .then(() => {
              resolve();
            }, error => {
              console.log('error', error);
              commit('setRetreatListLoading', false);
              commit('display/setError', { value: true }, { root: true });
              reject(error);
            });
        }, error => {
          console.log('error', error);
          commit('setRetreatListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  registerParticipant ({ state, commit, dispatch }, { userId }) {
    commit('setFocusRetreatLoading', true);
    return new Promise((resolve, reject) => {
      const retreatId = state.focusRetreat.id;
      const body = { userId };
      API.post(`/api/retreats/${retreatId}/participations`, body)
        .then(() => {
          dispatch('focusRetreat', state.focusRetreat.id)
            .then(() => {
              resolve();
            }, error => {
              console.log('error', error);
              commit('setFocusRetreatLoading', false);
              commit('display/setError', { value: true }, { root: true });
              reject(error);
            });
        }, error => {
          console.log('error', error);
          commit('setFocusRetreatLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  removeRegistration ({ commit, dispatch, state }, participationId) {
    commit('setFocusRetreatLoading', true);
    return new Promise((resolve, reject) => {
      const retreatId = state.focusRetreat.id;
      API.delete(`/api/retreats/${retreatId}/participations/${participationId}`)
        .then(() => {
          dispatch('focusRetreat', state.focusRetreat.id)
            .then(() => {
              resolve();
            }, error => {
              console.log('error', error);
              commit('setRetreatListLoading', false);
              commit('display/setError', { value: true }, { root: true });
              reject(error);
            });
        }, error => {
          console.log('error', error);
          commit('setRetreatListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
