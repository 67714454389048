<template>
  <a-row
    :gutter="[32, 32]"
    align="middle"
    class="home-row"
  >
    <a-col
      :md="12"
      :lg="16"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="statement"
        class="message"
        v-html="statement.contents"
      />
      <!--eslint-enable-->
    </a-col>
    <a-col
      :md="12"
      :lg="8"
    >
      <bible-passage
        v-if="verse"
        :source="verse.params.source"
      >
        {{ verse.contents }}
      </bible-passage>
    </a-col>
  </a-row>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import BiblePassage from '../components/BiblePassage';

export default {
  components: { BiblePassage },
  setup () {
    const store = useStore();

    const statement = computed(() => store.state.contents.homeStatement);
    const verse = computed(() => store.state.contents.homeVerse);
    onMounted(() => {
      store.dispatch('contents/getContent', { category: 'homeStatement', single: true });
      store.dispatch('contents/getContent', { category: 'homeVerse', single: true });
    });

    return {
      statement,
      verse
    };
  }
};
</script>

<style scoped>
.home-row {
  padding: 24px 0;
}
.message {
  font-size: var(--font-size-biggest);
  max-width: 50ch;
}
.home-video-container {
  text-align: center;
}
</style>
