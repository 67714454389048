<template>
  <iframe
    width="560"
    height="315"
    :src="videoLink"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    :class="sizeClass"
  />
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    }
  },
  setup (props) {
    const videoLink = computed(() => `https://www.youtube-nocookie.com/embed/${props.id}?rel=0&hl=lv&modestbranding=1`);

    const sizeClass = computed(() => `base-video-${props.size}`);

    return {
      videoLink,
      sizeClass
    };
  }
};
</script>

<style scoped>
iframe {
  display: block;
  width: calc(100vw - 64px);
  height: calc((100vw - 64px)/560*315);
  max-width: 800px;
  max-height: 450px;
  margin: 0 auto;
  border: 2px solid var(--color-background-secondary);
}
.base-video-small {
  max-width: 400px;
  max-height: 225px;
}
</style>
