<template>
  <a-modal
    :destroy-on-close="true"
    :ok-button-props="{ visible: false }"
    @ok="onSubmit"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
      >
        <a-form-item label="Rādīt">
          <a-switch
            v-model:checked="formState.show"
            checked-children="jā"
            un-checked-children="nē"
            data-testId="show"
          />
        </a-form-item>
        <a-form-item label="Sākums">
          <a-date-picker
            v-model:value="formState.deadline"
            :allow-clear="true"
            show-time
            data-testId="deadline"
          />
        </a-form-item>
        <a-form-item label="Video ID">
          <a-input
            v-model:value="formState.id"
            :allow-clear="true"
            data-testId="videoId"
          />
          <div
            class="video-container"
          >
            <base-video-youtube
              v-if="formState.id"
              :id="formState.id"
              size="small"
            />
          </div>
        </a-form-item>
        <a-form-item label="Paziņojums">
          <a-textarea
            v-model:value="formState.customMessage"
            :allow-clear="true"
            data-testId="customMessage"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import BaseVideoYoutube from './BaseVideoYoutube';

export default {
  components: { BaseVideoYoutube },
  emits: ['close'],
  setup (_, { emit }) {
    const store = useStore();

    const liveStream = computed(() => store.state.contents.liveStream);

    const formRef = ref();
    const formState = reactive({
      show: false,
      deadline: null,
      id: null,
      customMessage: null
    });

    const rules = {};

    const loading = ref(true);

    function onSubmit () {
      loading.value = true;
      formRef.value
        .validate()
        .then(() => {
          store.dispatch('contents/updateContentParams', {
            content: liveStream.value,
            params: {
              show: formState.show,
              deadline: formState.deadline ? formState.deadline.format('YYYY-MM-DD HH:mm') : null,
              id: formState.id,
              customMessage: formState.customMessage
            },
            single: true
          })
            .then(() => {
              loading.value = false;
              emit('close');
            });
        })
        .catch(error => {
          console.log(error);
          loading.value = false;
        });
    };

    onMounted(() => {
      store.dispatch('contents/getContent', { category: 'liveStream', single: true })
        .then(() => {
          const liveStream = store.state.contents.liveStream;
          formState.show = liveStream.params.show;
          formState.deadline = liveStream.params.deadline ? dayjs(liveStream.params.deadline, 'YYYY-MM-DD HH:mm') : null;
          formState.id = liveStream.params.id;
          formState.customMessage = liveStream.params.customMessage;
          loading.value = false;
        });
    });

    return {
      formRef,
      formState,
      rules,
      onSubmit,
      loading
    };
  }
};
</script>

<style scoped>
.video-container {
  margin: 16px 0;
  text-align: center;
}
</style>
