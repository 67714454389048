<template>
  <base-user-interest-card title="Individuālā kalpošana">
    <p>60 minūšu ilga saruna un aizlūgšanas.</p>
    <p>
      <strong>Tuvākā tikšanās:</strong>
      <span v-if="nextReservation">
        {{ nextReservation.date.format('LLL') }}
      </span>
      <span v-else>
        nav ieplānota
      </span>
    </p>
    <template #footer>
      <a-row :gutter="[8,8]">
        <a-col>
          <router-link to="/individuala-kalposana">
            <a-button>
              Skatīt vairāk
            </a-button>
          </router-link>
        </a-col>
        <a-col v-if="nextReservation">
          <router-link to="/individuala-kalposana#planotas">
            <a-button>
              Apskatīt plānotās tikšanās
            </a-button>
          </router-link>
        </a-col>
        <a-col>
          <router-link to="/individuala-kalposana#pieteikties">
            <a-button type="primary">
              Pieteikties
            </a-button>
          </router-link>
        </a-col>
      </a-row>
    </template>
  </base-user-interest-card>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import BaseUserInterestCard from './BaseUserInterestCard.vue';

export default {
  components: { BaseUserInterestCard },
  setup () {
    const store = useStore();

    const nextReservation = computed(() => store.state.careDates.myReservations ? store.state.careDates.myReservations[0] : null);

    onMounted(() => store.dispatch('careDates/getMyReservations'));

    return {
      nextReservation
    };
  }
};
</script>

<style>
</style>
