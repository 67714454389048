<template>
  <div
    v-for="(article, index) in news"
    :key="index"
    class="news-container"
  >
    <base-article
      :contents="article"
    />
  </div>
</template>

<script>
import BaseArticle from '../components/BaseArticle.vue';
import { useStore } from 'vuex';
import { computed, onMounted } from '@vue/runtime-core';

export default {
  components: { BaseArticle },
  setup () {
    const store = useStore();

    const news = computed(() => store.state.contents.homeNews);

    onMounted(() => store.dispatch('contents/getContent', { category: 'homeNews', single: false }));

    return {
      news
    };
  }
};
</script>

<style scoped>
.news-container {
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 3px solid var(--color-secondary);
}
</style>
