<template>
  <router-link :to="to">
    <ArrowRightOutlined />
    {{ text }}
  </router-link>
</template>

<script>
import { ArrowRightOutlined } from '@ant-design/icons-vue';

export default {
  components: { ArrowRightOutlined },
  props: {
    to: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: 'Uzzini vairāk'
    }
  }
};
</script>

<style>

</style>
