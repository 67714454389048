import { API } from '.';

const state = () => ({
  userList: [],
  userListLoading: false
});

const getters = {
};

const mutations = {
  setUserList (state, value) {
    state.userList = value;
  },

  setUserListLoading (state, value) {
    state.userListLoading = value;
  }
};

const actions = {
  getUserList ({ commit }) {
    commit('setUserListLoading', true);
    return new Promise((resolve, reject) => {
      API.get('/api/care-users')
        .then(({ data }) => {
          commit('setUserList', data);
          commit('setUserListLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setUserListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  updateParam ({ commit, dispatch }, { id, params }) {
    commit('setUserListLoading', true);
    return new Promise((resolve, reject) => {
      API.patch(`/api/care-users/${id}`, params)
        .then(() => {
          dispatch('getUserList')
            .then(resolve());
        }, error => {
          console.log('error', error);
          commit('setUserListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  addDate ({ commit, dispatch }, { id, date }) {
    commit('setUserListLoading', true);
    return new Promise((resolve, reject) => {
      API.post(`/api/care-users/${id}/care-dates`, { date })
        .then(() => {
          dispatch('getUserList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setUserListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  removeDate ({ commit, dispatch }, { id, date }) {
    commit('setUserListLoading', true);
    return new Promise((resolve, reject) => {
      API.delete(`/api/care-users/${id}/care-dates/${date.id}`)
        .then(() => {
          dispatch('getUserList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setUserListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  createUser ({ commit, dispatch }, { name, email }) {
    commit('setUserListLoading', true);
    return new Promise((resolve, reject) => {
      API.post('/api/care-users', { name, email })
        .then(() => {
          dispatch('getUserList')
            .then(() => resolve());
        }, error => {
          console.log('error', error);
          commit('setUserListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
