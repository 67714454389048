import { API } from '.';
// import dayjs from 'dayjs';

function getResourceParams (type) {
  return {
    visible: 1,
    published: 1,
    type
  };
};

const state = () => ({
  resourceList: [],
  resourceListLoading: false,
  tagList: []
});

const getters = {
};

const mutations = {
  setResourceList (state, value) {
    state.resourceList = value;
  },
  setResourceListLoading (state, value) {
    state.resourceListLoading = value;
  },
  setTagList (state, value) {
    state.tagList = value;
  }
};

const actions = {
  getResourceList ({ commit }, { type }) {
    return new Promise((resolve, reject) => {
      commit('setResourceListLoading', true);
      API.get('/api/resources', { params: getResourceParams(type) })
        .then(({ data }) => {
          commit('setResourceList', data);
          commit('setResourceListLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setResourceListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  getResourceAdminList ({ commit }, { type = null, visible = null, published = null }) {
    return new Promise((resolve, reject) => {
      commit('setResourceListLoading', true);
      const params = {};
      if (type !== null) {
        params.type = type;
      }
      if (visible !== null) {
        params.visible = visible;
      }
      if (published !== null) {
        params.published = published;
      }
      API.get('/api/resources', { params })
        .then(({ data }) => {
          commit('setResourceList', data);
          commit('setResourceListLoading', false);
          resolve();
        }, error => {
          console.log('error', error);
          commit('setResourceListLoading', false);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  getTagList ({ commit }) {
    return new Promise((resolve, reject) => {
      API.get('/api/tags')
        .then(({ data }) => {
          commit('setTagList', data);
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  createResource ({ commit, dispatch }, resource) {
    return new Promise((resolve, reject) => {
      API.post('/api/resources', resource)
        .then(() => {
          dispatch('getResourceAdminList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  updateResource ({ commit, dispatch }, resource) {
    return new Promise((resolve, reject) => {
      API.patch(`/api/resources/${resource.id}`, resource)
        .then(() => {
          dispatch('getResourceAdminList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  addResourceTag ({ commit, dispatch }, { id, tag }) {
    return new Promise((resolve, reject) => {
      API.post(`/api/resources/${id}/tags`, { tag })
        .then(() => {
          dispatch('getResourceAdminList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  removeResourceTag ({ commit, dispatch, state }, { id, tag }) {
    return new Promise((resolve, reject) => {
      API.delete(`/api/resources/${id}/tags/${tag}`)
        .then(() => {
          dispatch('getResourceAdminList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
