<template>
  <div class="the-footer">
    <router-link
      to="/privatuma-politika"
      class="privacy-link"
    >
      Privātuma politika
    </router-link>
    &copy; 2022 Mareks un Aija Ignati. Visas tiesības aizsargātas.
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.the-footer {
  background-color: var(--color-background-primary);
  color: var(--color-text-lightest);
  text-align: center;
  padding: 0.5rem 0;
  font-size: var(--font-size-small);
}
.privacy-link {
  color: var(--color-text-lightest);
  text-decoration-line: underline;
  display: block;
}
</style>
