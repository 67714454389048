<template>
  <div class="the-navigation">
    <a-menu
      :key="userName"
      v-model:selected-keys="selectedKeys"
      :mode="mode"
      class="main-menu"
      :class="{ 'user-menu': !isUser }"
      theme="light"
    >
      <template #overflowedIndicator>
        Izvēlne
        <menu-outlined />
      </template>
      <a-menu-item
        v-if="isUser"
        key="/"
      >
        <router-link to="/">
          Sākums
        </router-link>
      </a-menu-item>
      <a-sub-menu
        v-if="isUser"
      >
        <template #title>
          Programmas
        </template>
        <a-menu-item
          v-if="isUser"
          key="/rekolekcijas"
        >
          <router-link to="/rekolekcijas">
            Rekolekcijas
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item
          v-if="isUser"
          key="/padzilinata-programma"
        >
          <router-link to="/padzilinata-programma">
            Padziļinātā programma
          </router-link>
        </a-menu-item> -->
        <a-menu-item
          v-if="isUser"
          key="/individuala-kalposana"
        >
          <router-link to="/individuala-kalposana">
            Individuāla kalpošana
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu
        v-if="isUser"
      >
        <template #title>
          Resursi
        </template>
        <a-menu-item
          v-if="isUser"
          key="/jautajumi"
        >
          <router-link to="/jautajumi">
            Biežāk uzdotie jautājumi
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="isUser"
          key="/rekolekciju-ieraksti"
        >
          <router-link to="/rekolekciju-ieraksti">
            Rekolekciju videoieraksti
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="isUser"
          key="/svetdienas-sarunas"
        >
          <router-link to="/svetdienas-sarunas">
            Svētdienas sarunas
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item
        v-if="isUser"
        key="/par"
      >
        <router-link to="/par">
          Par mums
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="isUser"
        key="/atbalsts"
      >
        <router-link to="/atbalsts">
          Atbalsts
        </router-link>
      </a-menu-item>
      <a-menu-item
        class="menu-space"
        disabled
      />
      <a-sub-menu
        v-if="isUser"
      >
        <template #icon>
          <user-outlined />
        </template>
        <template #title>
          {{ userName }}
        </template>
        <a-menu-item
          v-if="isAdmin"
          key="/admin"
        >
          <template #icon>
            <team-outlined />
          </template>
          <router-link
            to="/admin"
          >
            Administrēt
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="isUser"
          key="logOut"
          @click="logOut"
        >
          <template #icon>
            <logout-outlined />
          </template>
          <span>
            Iziet
          </span>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item
        v-if="!isUser"
        key="/registreties"
      >
        <template #icon>
          <user-add-outlined />
        </template>
        <router-link to="/registreties">
          Reģistrēties
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="!isUser"
        key="/ienakt"
      >
        <template #icon>
          <login-outlined />
        </template>
        <router-link to="/ienakt">
          Ienākt
        </router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { UserOutlined, LogoutOutlined, TeamOutlined, UserAddOutlined, LoginOutlined, MenuOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  components: { UserOutlined, LogoutOutlined, TeamOutlined, UserAddOutlined, LoginOutlined, MenuOutlined },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'horizontal'
    }
  },
  setup () {
    const route = useRoute();
    const current = ref([]);
    const selectedKeys = ref([]);
    const store = useStore();
    const isUser = computed(() => store.getters['account/isUser']);
    const isAdmin = computed(() => store.getters['account/isAdmin']);

    const userName = computed(() => store.state.account.name);
    const userLoading = computed(() => store.state.account.loadingUser);

    function logOut () {
      store.dispatch('account/logout');
    }

    watch(
      () => route.name,
      newName => {
        current.value = [newName];
        selectedKeys.value = [route.path];
      }
    );

    return {
      current,
      isUser,
      isAdmin,
      userName,
      logOut,
      userLoading,
      selectedKeys
    };
  }
};
</script>

<style>
.menu-space {
  flex: 1 0 16px;
  cursor: default;
}
.main-menu {
  width: 100px;
}
.user-menu {
  width: 100%;
}
@media (min-width: 992px) {
  .main-menu {
    width: 100%
  }
}
</style>
