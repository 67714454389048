import { API } from '.';
import dayjs from 'dayjs';

function getContentParams (category) {
  return {
    visible: 1,
    published: 1,
    category
  };
};

const state = () => ({
  contentList: [],
  contentListLoading: false,

  homeStatement: null,
  homeVerse: null,
  homePrograms: [],
  homeNews: [],

  retreatsStatement: null,
  retreatEvent: [],
  retreatsActivity: [],

  advancedProgramStatement: null,
  advancedProgramActivity: [],
  advancedProgramRegister: null,
  advancedProgramCosts: null,

  faq: [],

  team: [],

  supportMain: null,
  supportAccount: [],

  spotlightResource: null
});

const getters = {
  liveStreamVisible: state => state.liveStream && state.liveStream.params.show,
  liveStreamDeadline: state => {
    if (state.liveStream) {
      if (state.liveStream.params.deadline) {
        const deadline = dayjs(state.liveStream.params.deadline, 'YYYY-MM-DD HH:mm');
        if (deadline >= dayjs()) {
          return deadline.format();
        }
      }
    }
    return null;
  },
  retreatPoster: state => {
    if (state.retreatEvent.length > 0 && state.retreatEvent[0].params.poster) {
      return process.env.BASE_URL + state.retreatEvent[0].params.poster;
    } else {
      return null;
    }
  }
};

const mutations = {
  setContent (state, { category, value }) {
    state[category] = value;
  },
  setContentList (state, value) {
    state.contentList = value;
  },
  setContentListLoading (state, value) {
    state.contentListLoading = value;
  }
};

const actions = {
  getContent ({ commit }, { category, single }) {
    return new Promise((resolve, reject) => {
      API.get('/api/contents', { params: getContentParams(category) })
        .then(({ data }) => {
          if (single) {
            commit('setContent', { category, value: data[0] });
          } else {
            commit('setContent', { category, value: data });
          }
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  getContentList ({ commit }, { category = null, visible = null, published = null }) {
    return new Promise((resolve, reject) => {
      commit('setContentListLoading', true);
      const params = {};
      if (category !== null) {
        params.category = category;
      }
      if (visible !== null) {
        params.visible = visible;
      }
      if (published !== null) {
        params.published = published;
      }
      API.get('/api/contents', { params })
        .then(({ data }) => {
          commit('setContentList', data);
          commit('setContentListLoading', false);
        }, error => {
          console.log('error', error);
          commit('setContentListLoading', false);
          commit('display/setError', { value: true }, { root: true });
        });
    });
  },
  createContent ({ commit, dispatch }, content) {
    content.isPublished = false;
    content.params = {};
    return new Promise((resolve, reject) => {
      API.post('/api/contents', content)
        .then(() => {
          dispatch('getContentList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  updateContent ({ commit, dispatch }, content) {
    return new Promise((resolve, reject) => {
      API.patch(`/api/contents/${content.id}`, content)
        .then(() => {
          dispatch('getContentList', {});
          resolve();
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  updateContentParam ({ commit, dispatch }, { content, param, value, single }) {
    return new Promise((resolve, reject) => {
      const id = content.id;
      const newParams = { ...content.params };
      const category = content.category;

      newParams[param] = value;
      API.patch(`/api/contents/${id}`, { params: newParams })
        .then(() => {
          dispatch('getContent', { category, single })
            .then(() => resolve(), error => reject(error));
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  },
  updateContentParams ({ commit, dispatch }, { content, params, single }) {
    return new Promise((resolve, reject) => {
      const id = content.id;
      const category = content.category;

      API.patch(`/api/contents/${id}`, { params })
        .then(() => {
          dispatch('getContent', { category, single })
            .then(() => resolve(), error => reject(error));
        }, error => {
          console.log('error', error);
          commit('display/setError', { value: true }, { root: true });
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
