<template>
  <a-card
    hoverable
    class="base-card"
  >
    <h2>
      {{ title }}
    </h2>
    <slot />
    <slot name="footer" />
  </a-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.vertical-container {
  flex-flow: column;
  height: 100%;
}
.base-card {
  margin: 0;
  height: 100%;
}
.base-card:deep(.ant-card-body) {
  height: 100%;
}
</style>
