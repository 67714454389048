import { API } from '.';

const state = () => ({
  error: false,
  errorText: 'Atvainojiet, notika neparedzēta kļūda!',
  errorOnOkCallback: () => { location.reload(); },
  apiVersion: null
});

const getters = {

};

const mutations = {
  setError (state, { value, text = 'Atvainojiet, notika neparedzēta kļūda!', callBack = () => { location.reload(); } }) {
    state.error = value;
    state.errorText = text;
    state.errorOnOkCallback = callBack;
  },

  setApiVersion (state, version) {
    state.apiVersion = version;
  }
};

const actions = {
  getApiVersion ({ commit }) {
    API.get('/api/version')
      .then(({ data }) => {
        commit('setApiVersion', data.version);
      });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
