<template>
  <div class="bible-passage">
    <div :class="sizeClass">
      <slot />
    </div>
    <div class="bible-source">
      {{ source }}
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
export default {
  props: {
    source: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    }
  },
  setup (props) {
    const sizeClass = computed(() => `bible-text-${props.size}`);
    return {
      sizeClass
    };
  }
};
</script>

<style scoped>
.bible-passage {
  background-color: var(--color-background-secondary);
  border-left: 24px solid var(--color-background-primary);
  padding: 32px 16px;
  box-shadow: var(--shadow);
}
.bible-text-large,
.bible-text-small,
.bible-source {
  font-family: var(--font-accent);
}
.bible-text-large {
  font-size: var(--font-size-bigger);
}
.bible-text-small {
  font-size: var(--font-size-base);
}
.bible-source {
  font-size: var(--font-size-small);
  text-align: right;
}
</style>
