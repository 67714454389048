<template>
  <div
    class="live-stream-container"
  >
    <base-title>
      Rekolekciju tiešraide
    </base-title>
    <div class="container-main">
      <a-row
        :gutter="[24,24]"
        type="flex"
        justify="space-around"
      >
        <a-col
          class="video-column"
          flex="0 0 auto"
        >
          <base-video-youtube
            v-if="videoId"
            :id="videoId"
          />
          <div
            v-else
            class="empty-video"
          >
            <div class="empty-video-inner">
              <div
                v-if="deadline"
              >
                <div class="empty-video-text">
                  Līdz tiešraides sākumam
                </div>
                <a-statistic-countdown
                  :value="deadline"
                />
              </div>
              <div
                v-else
                class="empty-video-text"
              >
                Tiešraide sāksies pēc brīža...
              </div>
              <youtube-filled
                class="youtube-icon"
              />
            </div>
          </div>
          <div
            v-if="customMessage"
            class="custom-message"
          >
            {{ customMessage }}
          </div>
          <live-stream-video-change v-if="isAdmin" />
        </a-col>
        <a-col
          flex="1 1 200px"
          class="question-column"
        >
          <div
            v-if="isAdmin"
            class="question-list"
          >
            <live-stream-question-show />
          </div>
          <live-stream-question-ask
            v-else
            :disabled="!videoId"
          />
          <div v-if="activeUsers.length">
            <a-badge
              :count="activeUsers.length"
              :number-style="{ backgroundColor: '#52c41a' }"
            />
            tiešsaistē:
            <div class="active-users">
              <a-tag
                v-for="(user, index) in activeUsers"
                :key="index"
              >
                {{ user.name }}
              </a-tag>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { YoutubeFilled } from '@ant-design/icons-vue';
import { useStore } from 'vuex';

import BaseTitle from './BaseTitle';
import LiveStreamQuestionAsk from './LiveStreamQuestionAsk';
import LiveStreamQuestionShow from './LiveStreamQuestionShow';
import LiveStreamVideoChange from './LiveStreamVideoChange';
import BaseVideoYoutube from './BaseVideoYoutube';

export default {
  components: { BaseTitle, YoutubeFilled, LiveStreamQuestionAsk, LiveStreamQuestionShow, BaseVideoYoutube, LiveStreamVideoChange },
  setup () {
    const store = useStore();

    const liveStream = computed(() => store.state.contents.liveStream);

    const deadline = computed(() => store.getters['contents/liveStreamDeadline']);
    const videoId = computed(() => liveStream.value ? liveStream.value.params.id : null);
    const customMessage = computed(() => liveStream.value ? liveStream.value.params.customMessage : null);

    const isAdmin = computed(() => store.getters['account/isAdmin']);

    const activeUsers = computed(() => store.state.users.activeUsers);

    let intervalID = null;

    function refreshData () {
      store.dispatch('contents/getContent', { category: 'liveStream', single: true });
      store.dispatch('users/getActiveUsers');
    }

    onMounted(() => {
      store.dispatch('users/getActiveUsers');
      intervalID = window.setInterval(refreshData, 10000);
    });

    onBeforeUnmount(() => {
      clearInterval(intervalID);
    });

    return {
      deadline,
      videoId,
      customMessage,
      isAdmin,
      activeUsers
    };
  }
};
</script>

<style scoped>
.video-column {
  text-align: center;
}
.question-column {
  max-width: 800px;
}
.youtube-icon {
  color: var(--color-disabled);
  font-size: 300%;
}
.empty-video {
  width: calc(100vw - 64px);
  height: calc((100vw - 64px)/560*315);
  max-width: 800px;
  max-height: 450px;
  margin: 0 auto;
  border: 2px solid var(--color-background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-video-inner {
  text-align: center;
}
.empty-video-text {
  font-size: var(--font-size-bigger);
}
.question-list {
  height: 450px;
  overflow-y: auto;
}
.active-users {
  max-height: 190px;
  overflow-y: auto;
}
.custom-message {
  width: calc(100vw - 64px);
  max-width: 800px;
  text-align: left;
}
</style>
