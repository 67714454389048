import { API } from '.';

const state = () => ({
  userList: [],
  userListLoading: false,
  userRoleLoading: {},
  activeUsers: []
});

const getters = {
  authToken (_, __, rootState) {
    return rootState.account.token;
  },

  userCount (state) {
    return state.userList.length;
  }
};

const mutations = {
  setUserList (state, value) {
    state.userList = value;
  },

  setUserListLoading (state, value) {
    state.userListLoading = value;
  },

  setUserRoleLoading (state, { userID, value }) {
    state.userRoleLoading[userID] = value;
  },

  setUserRole (state, { userID, role }) {
    const user = state.userList.find(element => element.id === userID);
    if (user) {
      user.role = role;
    }
  },

  setActiveUsers (state, value) {
    state.activeUsers = value;
  }
};

const actions = {
  getUserList ({ commit }, { role }) {
    commit('setUserListLoading', true);
    const params = {};
    if (role || role === 0) {
      params.role = role;
    }
    API.get('/api/users', { params })
      .then(({ data }) => {
        commit('setUserList', data);
        commit('setUserListLoading', false);
      }, error => {
        console.log('error', error);
        commit('setUserListLoading', false);
        commit('display/setError', { value: true }, { root: true });
      });
  },

  setUserRole ({ commit }, { userID, role }) {
    commit('setUserRoleLoading', { userID, value: true });
    API.patch(`/api/users/${userID}`, { role })
      .then(() => {
        commit('setUserRole', { userID, role });
        commit('setUserRoleLoading', { userID, value: false });
      }, error => {
        console.log('error', error);
        commit('setUserRoleLoading', { userID, value: false });
        commit('display/setError', { value: true }, { root: true });
      });
  },

  getActiveUsers ({ commit }) {
    API.get('/api/users/active')
      .then(({ data }) => {
        commit('setActiveUsers', data);
      }, error => {
        console.log('error', error);
        commit('setActiveUsers', []);
        commit('display/setError', { value: true }, { root: true });
      });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
