import { createStore } from 'vuex';
import axios from 'axios';
import account from './account';
import display from './display';
import users from './users';
import careUsers from './careUsers';
import contents from './contents';
import questions from './questions';
import careDates from './careDates';
import retreats from './retreats';
import downloads from './downloads';
import resources from './resources';

export const API = axios.create();

export function setAuthToken (token) {
  if (token) {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    API.defaults.headers.common.Authorization = '';
  }
};

export default function () {
  const store = createStore({
    modules: {
      account,
      display,
      users,
      careUsers,
      contents,
      questions,
      careDates,
      retreats,
      downloads,
      resources
    }
  });

  API.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const originalConfig = error.config;
    if (originalConfig.url !== '/api/token' && error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        store.dispatch('account/refreshToken')
          .then(() => {
            return API(originalConfig);
          }, (error) => {
            console.log(error);
            setAuthToken('');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
          });
      }
    }
    return Promise.reject(error);
  });

  return store;
};
