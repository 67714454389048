import { API } from '.';

const state = () => ({
  showSuccessful: false,
  showSending: false,
  showError: false,
  questions: [],
  filter: 'unanswered'
});

const getters = {

};

const mutations = {
  statusNone (state) {
    state.showSuccessful = false;
    state.showSending = false;
    state.showError = false;
  },
  statusSending (state) {
    state.showSending = true;
    state.showSuccessful = false;
    state.showError = false;
  },
  statusSuccessful (state) {
    state.showSuccessful = true;
    state.showSending = false;
    state.showError = false;
  },
  statusError (state) {
    state.showSuccessful = false;
    state.showSending = false;
    state.showError = true;
  },
  setQuestions (state, value) {
    state.questions = value;
  },
  setFilter (state, value) {
    state.filter = value;
  }
};

const actions = {
  askQuestion ({ commit }, text) {
    commit('statusSending', true);
    API.post('/api/questions', { text })
      .then(() => {
        commit('statusSuccessful', true);
      }, () => {
        commit('statusError', true);
      });
  },
  getQuestions ({ commit, state }) {
    const params = {};
    if (state.filter === 'unanswered') {
      params.answered = false;
    }
    API.get('/api/questions', { params })
      .then(({ data }) => {
        commit('setQuestions', data);
        commit('statusNone');
      });
  },
  markAnswered ({ commit, dispatch }, id) {
    commit('statusSending', true);
    API.patch(`/api/questions/${id}`, { answered: true })
      .then(() => {
        dispatch('getQuestions');
      });
  },
  setFilter ({ commit, dispatch }, value) {
    commit('setFilter', value);
    dispatch('getQuestions');
  }
};

export default { state, getters, mutations, actions, namespaced: true };
