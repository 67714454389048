<template>
  <a-button
    type="primary"
    size="large"
    class="show-modal-button"
    data-testId="changeLiveStreamSettings"
    @click="showModal"
  >
    Mainīt tiešraides iestatījumus
  </a-button>
  <live-stream-settings-modal
    v-model:visible="modalVisible"
    @close="closeModal"
  />
</template>

<script>
import { ref } from 'vue';

import LiveStreamSettingsModal from './LiveStreamSettingsModal';

export default {
  components: { LiveStreamSettingsModal },
  setup () {
    const modalVisible = ref(false);

    function showModal () {
      modalVisible.value = true;
    }

    function closeModal () {
      modalVisible.value = false;
    }

    return {
      modalVisible,
      showModal,
      closeModal
    };
  }
};
</script>

<style scoped>
.show-modal-button {
  margin-top: 16px;
}
.video-container {
  margin: 16px 0;
  text-align: center;
}
</style>
