<template>
  <a-form
    ref="formRef"
    :model="formState"
    :rules="rules"
    layout="vertical"
  >
    <a-form-item
      label="Uzdod jautājumu"
      name="question"
      :class="{disabled: disabled}"
    >
      <a-textarea
        v-model:value="formState.question"
        :auto-size="{ minRows: 4, maxRows: 4 }"
        :disabled="inputDisabled"
        data-testId="question"
      />
    </a-form-item>
    <a-form-item>
      <a-alert
        v-if="showSuccessful"
        message="Jautājums nosūtīts"
        type="success"
        closable
        show-icon
        :after-close="handleClose"
      />
      <a-alert
        v-else-if="showError"
        message="Jautājumu neizdevās nosūtīt. Mēģiniet vēlreiz."
        type="error"
        closable
        show-icon
        :after-close="handleClose"
      />
      <a-button
        v-else
        type="primary"
        size="large"
        :disabled="disabled"
        :loading="showSending"
        data-testId="save"
        @click="onSubmit"
      >
        Sūtīt
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { useStore } from 'vuex';
import { ref, reactive, computed, watch } from 'vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const store = useStore();

    const showSuccessful = computed(() => store.state.questions.showSuccessful);
    const showSending = computed(() => store.state.questions.showSending);
    const showError = computed(() => store.state.questions.showError);

    const formRef = ref();
    const formState = reactive({
      question: ''
    });

    const rules = {
      question: [
        {
          required: true,
          message: 'Lūdzu, ievadiet jautājumu',
          trigger: 'blur'
        }
      ]
    };

    watch(showSuccessful, (value) => {
      if (value) {
        formState.question = '';
        setTimeout(() => store.commit('questions/statusNone'), 5000);
      }
    });

    const inputDisabled = computed(() => props.disabled || showSending.value);

    function onSubmit () {
      formRef.value
        .validate()
        .then(() => {
          store.commit('questions/statusNone');
          store.dispatch('questions/askQuestion', formState.question);
        })
        .catch(() => {});
    };

    function handleClose () {
      store.commit('questions/statusNone');
    }

    return {
      formRef,
      formState,
      rules,
      onSubmit,
      inputDisabled,
      showSuccessful,
      showSending,
      showError,
      handleClose
    };
  }
};
</script>

<style scoped>
.disabled,
.disabled:deep(.ant-form-item-label > label) {
  color: var(--color-disabled);
}
</style>
