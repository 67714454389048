<template>
  <base-user-interest-card title="Rekolekcijas">
    <p>
      {{ eventTitle }}
    </p>
    <template #footer>
      <router-link :to="eventLink">
        <a-button>
          Skatīt vairāk
        </a-button>
      </router-link>
    </template>
  </base-user-interest-card>
</template>

<script>
import { computed } from 'vue';
import BaseUserInterestCard from './BaseUserInterestCard.vue';

export default {
  components: { BaseUserInterestCard },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const eventTitle = computed(() => props.event.params.header);
    const eventLink = computed(() => `/rekolekcijas#${props.event.params.key}`);

    return {
      eventTitle,
      eventLink
    };
  }
};
</script>

<style>

</style>
