<template>
  <div class="title-full-width">
    <slot />
  </div>
</template>

<style>
.title-full-width {
  background-color: var(--color-background-secondary);
  font-family: var(--font-accent);
  font-size: var(--font-size-large);
  text-align: center;
  padding: 16px;
}
</style>
