<template>
  <div class="the-header">
    <div class="navigation">
      <TheNavigation class="nav-horizontal" />
    </div>
    <a-row
      type="flex"
      justify="space-between"
      class="header-image"
    >
      <a-col
        class="title"
        :class="{'title-large': !route.name}"
      >
        Dvēseļkopšanas un atbrīvošanas kalpošana
      </a-col>
      <a-col
        v-if="route.name"
        class="page-title"
      >
        <span>
          {{ route.name }}
        </span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import TheNavigation from './TheNavigation';
import { useRoute } from 'vue-router';

export default {
  components: { TheNavigation },
  setup () {
    const route = useRoute();

    return {
      route
    };
  }
};
</script>

<style scoped>
.the-header {
  background-color: var(--color-menu-background);
}
.header-image {
  height: calc(80px + 11vw);
  background-image: url('~@/assets/title_image.jpg');
  background-position: center bottom;
  background-size: 100% auto;
  flex-flow: column nowrap;
}
.title {
  font-size: 1rem;
  line-height: 1rem;
  color: var(--color-text-lightest);
  font-family: var(--font-title);
  width: 20ch;
  padding: 8px 12px;
}
.title-large {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: auto;
}
.page-title {
  font-size: 1.5rem;
  color: var(--color-text-lightest);
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 12px;
  font-family: var(--font-accent);
}
.navigation {
  max-width: 75rem;
  margin: 0 auto;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .title {
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 12px 18px;
  }
  .title-large,
  .page-title {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
  .page-title {
    padding: 18px;;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 2rem;
    line-height: 2rem;
    padding: 16px 24px;
  }
  .title-large,
  .page-title {
    font-size: 3rem;
    line-height: 3rem;
  }
  .page-title {
    padding: 24px;
  }
}
@media (min-width: 1200px) {
  .title {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
  .title-large,
  .page-title {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}
@media (min-width: 1500px) {
  .title {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .title-large,
  .page-title {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
</style>
