<template>
  <div class="question-item">
    <div
      :class="{answered: question.answered}"
    >
      {{ question.text }}
    </div>
    <a-button
      v-if="!question.answered"
      type="primary"
      size="large"
      class="answered-button"
      data-testId="markAnswered"
      @click="markAnswered(question.id)"
    >
      Atbildēts
    </a-button>
  </div>
</template>

<script>
import { useStore } from 'vuex';
export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  setup () {
    const store = useStore();

    function markAnswered (id) {
      store.dispatch('questions/markAnswered', id);
    }

    return {
      markAnswered
    };
  }
};
</script>

<style scoped>
.question-item {
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-background-secondary);
}
.answered-button {
  margin-top: 8px;
}
.answered {
  color: var(--color-text-lighter);
}
</style>
